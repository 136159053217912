import * as React from "react";
import {useEffect, useMemo} from "react";
import jsonRequest from "../../utils/request/jsonRequest";
import * as queryString from "query-string"
import {message, Spin} from "antd";
import {navigate} from "gatsby";
import auth from "../../utils/auth";
import Seo from "../../components/seo"

const GithubOAuth = ({location}) => {
  const params = useMemo(() => queryString.parse(location.search), [location])

  useEffect(() => {
    jsonRequest("/oauth/github", {
      method: 'POST',
      data: params,
    }).then(rsp => {
      if (rsp.success) {
        if (rsp.data.data) {
          auth.setToken(rsp.data.data)
          message.success("授权登录成功");
          navigate("/")
          return;
        } else {
          message.success("错误")
        }
      } else {
        message.error(rsp.message)
      }
      navigate("/sign/in")
    }).catch(console.error)
  }, [])

  return (<>
    <div style={{textAlign: 'center', padding: 60}}>
      <Spin />
    </div>
  </>)
}

export default GithubOAuth

export const Head = () => <Seo />
